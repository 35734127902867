
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.navbar-nav .toggle {
    display: flex;
    width: 100px;
    align-items: center; /* Align items vertically in the center */
    justify-content: start;
    padding: 15px 20px; /* Adjust padding to match other nav items */
    cursor: pointer;
   

  }
  
  .navbar-nav .toggle .icon {
    margin-right: 8px; /* Space between the icon and the text */
  }
  
  .navbar-nav .toggle:hover {
    /*background-color: #f8f8f8; /* Optional: Add a hover effect */
  }


  /* styles.css */
.rtl {
  direction: rtl;
}


